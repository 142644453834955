<!--
 * @Author: your name
 * @Date: 2020-04-23 16:12:30
 * @LastEditTime: 2021-04-27 09:13:57
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /cunw-youzhiduo-OS/src/views/useHelp/index.vue
-->

<template>
  <div class="aboutUs-page">
    <div class="aboutUs-main ">
      <div class="section">
        <div class="title" style="margin:30px 0 20px">
          公司简介
        </div>
        <div class="compony-image">
          <img src="../../assets/images/os/aboutUs1.png" alt="">
        </div>
        <div class="dec">
          <div class="part" style="margin-top:10px">
            湖南新云网科技有限公司旗下的湖南优智多科技有限公司、北京新云网科技有限公司等多家子公司专注于校园管理信息化、教育信息化解决方案建设， 并不断拓展多行业信息化应用服务，在智慧教育领域拥有了自己的核心技术和产品。目前，拥有包括发明专利在内的各类知识产权 700 余件， 并与国内外顶级 IT企业建立了长期稳定的合作关系。
          </div>
          <div class="part">
            公司以“做有温度的教育，让成长更快乐”为出发点，致力于做智慧教育的先行者，坚持自主创新，自主研发。依托自身科技服务能力，将信息技术与教育教学深度融合， 为客户提供教育行业产业互联网科技服务、物联网科技服务。目前公司已经研发出数字化校园平台与应用、教务管理系统、大数据服务平台、移动互联网服务、多场景物联网智能终端在内的各类应用系统，形成了“一套系统创云校、一张讲台连万校、一台终端牵家校”的智慧教育生态圈，构建了“互联网+教育”新生态，加快实现了“专递课堂”“名师课堂”“名校网络课堂”常态化运用，创新了教育教学新模式，推进形成智慧教育新格局。 为区域教育管理和统一协调提供帮助，全面提升教育信息化素养，实现学校、家庭、社会的共育共享，推动优质教育资源共享，加快实现教育均衡发展。
          </div>
          <div class="part">
            同时，公司基于“智能物联、移动互联、数据决策、融合共享”的总体设计思路理念，构建了优智多教育生态体系，包含课桌先生、优智多课堂、优智多商城，业务涵盖智能家教学习桌、线上教育、线上文创商城和线下文创门店等，致力于提供一站式教育服务解决方案。 优智多秉承“做时代经典，服务亿万民众”的企业愿景，承载“让科技服务民生”的使命，坚持“追求卓越，永不懈怠”的核心价值观，专注于“至诚至信，精益求精”的经营理念，用技术服务教育领域，用互联网科技赋能教育发展。以“敬业兴企，匠心筑梦”的工匠精神，促进信息化时代教育变革，用科技推动教育进步，让学习有更美好体验。
          </div>
        </div>
      </div>
      <div class="section section-mb">
        <div class="flex-box" style="margin-top:42px;">
          <div v-for="(item, index) in aboutYouzd" :key="index" class="box">
            <div class="image">
              <img :src="item.image" alt="" @click="playVideo(item.type)">
            </div>
            <div class="image" />
          </div>
        </div>
      </div>
      <el-dialog
        :visible.sync="centerDialogVisible"
        width="80%"
        center
        class="alert-vid-block"
        @close="closeVideo"
      >
        <div class="img-box">
          <video id="movie" src="https://cunw-edu-live-static.oss-cn-hangzhou.aliyuncs.com/show/%E4%BC%98%E6%99%BA%E5%A4%9A%E9%97%A8%E5%BA%97%E8%A7%86%E9%A2%91(2).m4v" controls="controls" />
        </div>
        <!-- <span slot="footer" class="dialog-footer">
          <el-button @click="centerDialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="centerDialogVisible = false">确 定</el-button>
        </span> -->
      </el-dialog>
      <div v-if="sourceFrom" class="news-title">
        <div class="title">
          新闻资讯
        </div>
        <div class="more" @click="goMore">
          更多>
        </div>
      </div>
      <div v-if="sourceFrom" class="section  section-mb-news">
        <div class="flex-box">
          <div v-for="(item, index) in newArr" :key="index" class=" box new-box">
            <router-link :to="item.linkUrl">
              <div class="image">
                <img :src="item.image" alt="">
              </div>
              <div class="dec-title" :style="{'color':item.color?item.color:''}">
                {{ item.title }}
              </div>
              <div class="dec">
                {{ item.dec }}
              </div>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import about2 from '../../assets/images/os/aboutUs2.png';
import about3 from '../../assets/images/os/aboutUs3.png';
import news1 from '../../assets/images/os/aboutUs4.png';
import news2 from '../../assets/images/os/aboutUs5.png';
import news3 from '../../assets/images/os/aboutUs6.png';
export default {
  name: 'AboutUs',
  data() {
    return {
      aboutYouzd: [
        {
          image: about3,
          title: '儿童学习桌领导品牌',
          dec: '以器载道，以物传情，聚焦青少年对学习和生...',
          type: 'vid'
        },
        {
          image: about2,
          title: '优智多在您身边',
          dec: '以器载道，以物传情，聚焦青少年对学习和生...',
          type: 'img'
        }
      ],
      newArr: [
        {
          image: news1,
          title: '中共中央政治局常委、国务院副总理韩正来我...',
          dec: '2020年6月1日，中共中央政治局常委、国务院副总理韩正...',
          linkUrl: '/indexOs/newsDetails1'
          // color:"#FD9C02"
        },
        {
          image: news2,
          title: '岳麓山大学科技城：强化科技人才支撑 打造湖南...',
          dec: '在中南大学科技园研发总部，15位联络员负责对接园区51家...',
          linkUrl: '/indexOs/newsDetails2'
        },
        {
          image: news3,
          title: '大科城软件产业联盟拍了拍你，新云网智慧亮剑！',
          dec: '大科城软件产业联盟拍了拍你，新云网智慧亮剑！',
          linkUrl: '/indexOs/newsDetails3'
        }
      ],
      // 网页使用场景
      sourceFrom: true,
      centerDialogVisible: false
    };
  },
  mounted() {
    this.sourceFrom = !this.$route.query.source;
  },
  methods: {
    goMore() {
      this.$router.push('/indexOs/newsList');
    },
    playVideo(type) {
      if (type === 'vid') {
        this.centerDialogVisible = true;
        // var vid = document.getElementById('movie');
        // vid.play();
      }
    },
    // 关闭弹窗
    closeVideo() {
      var vid = document.getElementById('movie');
      vid.pause();
    }
  }
};
</script>

<style lang="scss">
@media screen and (min-width: 1400px) {
  .alert-vid-block{
    video{
      width: 100%;
    }
  }
  .border {
    border: 1px solid black;
  }
  .aboutUs-page {
    background: #f5f5f5;
    .aboutUs-main {
      width: 1200px;
      margin: 0 auto;
      background: #f5f5f5;
      padding-bottom: 50px;
      .section {
        width: 100%;
        .title {
          font-size: 30px;
          line-height: 53px;
          color: #fd9c02;
        }
        .more{
          height: 20px;
          text-align: right;
          color: #313131;
          font-size: 12px;
          cursor: pointer;
          &:hover{
                color: #FD9C02;
              }
        }
        .flex-box{
          display: flex;
          justify-content: space-between;

          .box{
            width:560px;
            // border: 1px solid black;
            .image{
              width:100%;
              img{
                width:100%;
              }
            }
            .dec-title{
              color: #313131;
              font-size: 18px;
              font-weight: 500;
              margin-top: 20px;
              font-weight: bolder;
              overflow: hidden;
              text-overflow:ellipsis;
              white-space: nowrap;
              cursor: pointer;
              &:hover{
                color: #FD9C02;
              }
            }
            .dec{
              color:#959595;
              font-size: 14px;
              cursor: pointer;
              overflow: hidden;
              text-overflow:ellipsis;
              white-space: nowrap;
              margin-top: 10px;
            }

          }
          .new-box{
            width:370px;
          }

        }
        .compony-image {
          width: 100%;
          img {
            width: 100%;
          }
        }
        .dec {
          margin-top: 36px;
          .part {
            text-indent: 36px;
            line-height: 36px;
            font-size: 18px;
            color: #313131;
            text-align: justify;
          }
        }
      }
      .news-title{
        display:flex;
        justify-content: space-between;
        align-items: center;
        margin:30px 0;
        .title {
          font-size: 30px;
          line-height: 53px;
          color: #fd9c02;
        }
        .more{
          height: 20px;
          text-align: right;
          color: #313131;
          font-size: 12px;
          cursor: pointer;
          &:hover{
                color: #FD9C02;
              }
        }
      }
    }
  }
}
@media screen and (max-width: 1400px) {
  .alert-vid-block{
    video{
      width: 100%;
    }
  }
  .border {
    border: 1px solid black;
  }
  .aboutUs-page {
    background: #fff;
    padding: 0 .53rem;
    padding-bottom: 50px;
    .aboutUs-main {
      width: 100%;
      .section {
        width: 100%;
        overflow-x: auto;
        .title {
          font-size: .8rem;
          color: #fd9c02;
          font-weight: 600;
        }
        .more{
          text-align: right;
          color: #313131;
          font-size: .373rem;
          cursor: pointer;
        }
        .flex-box{
          display: flex;
          justify-content: space-between;
          .box{
            width:8.027rem;
            .image{
              width:100%;
              img{
                width:100%;
              }
            }
            .dec-title{
              color: #313131;
              font-size: 18px;
              font-weight: 500;
              margin-top: 20px;
              font-weight: bolder;
              overflow: hidden;
              text-overflow:ellipsis;
              white-space: nowrap;
              cursor: pointer;
              &:hover{
                color: #FD9C02;
              }
            }
            .dec{
              color:#959595;
              font-size: 14px;
              cursor: pointer;
              overflow: hidden;
              text-overflow:ellipsis;
              white-space: nowrap;
              margin-top: 10px;
            }

          }
          .new-box{
            width:370px;
          }

        }
        .compony-image {
          width: 100%;
          img {
            width: 100%;
          }
        }
        .dec {
          margin-top: .53rem;
          .part {
            text-indent: .747rem;
            line-height: .6rem;
            font-size: .373rem;
            color: #313131;
            text-align: justify;
          }
        }
      }
      .news-title{
        display:flex;
        justify-content: space-between;
        align-items: center;
        margin:30px 0;
        .title {
          font-size: .8rem;
          color: #fd9c02;
          font-weight: 600;
        }
        .more{
          text-align: right;
          color: #313131;
          font-size: .373rem;
          cursor: pointer;
        }
      }
      .section-mb{
        .flex-box{
          width: 16.32rem;
        }
      }
      .section-mb-news{
        width: 100%;
        overflow-x: auto;
        .flex-box{
          width: 24.561rem;
          .new-box{
            width: 8.027rem;
            .dec-title{
              font-size: .373rem;
            }
            .dec{
              display: none;
            }
          }
        }
      }
    }
  }
}
</style>
